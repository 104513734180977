var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.subscriptionPopupFormV1 = {
    attached: false,

    submitPopupForm: function (event) {
      event.preventDefault();
      var $subscriptionPopupForm = $(this);
      var $subscriptionPopupFormErrors = $subscriptionPopupForm.find('.js-subscribe-popup-errors');
      var $subscriptionPopupFormThanks = $subscriptionPopupForm
        .closest('.js-subscribe-popup-wrapper')
        .find('.js-popup-form-thanks');

      $('input, select, .js-email-promotions', $subscriptionPopupForm).removeClass('error');
      var parametersArray = $subscriptionPopupForm.serializeArray();
      var parametersObject = {};

      $.each(parametersArray, function (index, param) {
        parametersObject[param.name] = param.value;
        if (param.name === 'PC_EMAIL_PROMOTIONS') {
          parametersObject['ACCEPTED_PRIVACY_POLICY'] = 1;
        }
      });

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [parametersObject],
        onSuccess: function () {
          $subscriptionPopupFormErrors.hide();
          $subscriptionPopupFormThanks.removeClass('hidden');
          $subscriptionPopupForm.addClass('hidden');
          $.colorbox.resize();
        },
        onFailure: function (jsonRpcResponse) {
          var errorObjectsArray = jsonRpcResponse.getMessages();
          var errorHTML = '';
          var errorOrder = [
            'required.first_name.find_your_signature',
            'required.last_name.find_your_signature',
            'required.email_address.find_your_signature',
            'required.select_country.find_your_signature',
            'required.accepted_privacy_policy.find_your_signature'
          ];

          errorObjectsArray.sort(function (a, b) {
            return errorOrder.indexOf(a.key) - errorOrder.indexOf(b.key);
          });
          errorObjectsArray.forEach(function (errorObject) {
            errorHTML += '<li>' + errorObject.text + '</li>';
            var fieldName = errorObject.key ? errorObject.key.match(/\w*.(\w*)./) : '';

            // Check if there is a match.
            if (fieldName && fieldName[1]) {
              var $node = $('[name=' + fieldName[1].toUpperCase() + ']', $subscriptionPopupForm);

              if (fieldName[1] === 'accepted_privacy_policy') {
                $subscriptionPopupForm.find('.js-email-promotions').addClass('error');
              }
              $node.addClass('error');
            }
          });
          $subscriptionPopupFormErrors.html(errorHTML).show();
          $.colorbox.resize();
        }
      });
    },

    showOverlayForm: function () {
      generic.overlay.launch({
        html: site.template.get({ name: 'subscription_popup' }),
        onComplete: function () {
          var $popupForm = $('.js-subscribe-popup--v1');
          var $countrySelect = $('.js-select-country', $popupForm);
          var $popupInput = $('.js-subscribe-popup input[type=text]', $popupForm);

          $('body').addClass('newsletter-overlay');

          $countrySelect.on('change', function () {
            var $selectedCountry = $(this).find('option:selected');
            var languageId;

            if ($selectedCountry.length) {
              languageId = $selectedCountry.attr('data-language-id');
              if (typeof languageId !== 'undefined') {
                $('.js-language-id').val(languageId);
              }
            }
          });
          Drupal.behaviors.adaptivePlaceholders.setupDOM($popupInput);
          $.colorbox.resize();
        }
      });
    },

    checkCookie: function (settings) {
      var subscriptionCookie = $.cookie('popupCookie');
      var disableNewsletterSignupPopup = settings.common.disable_newsletter_signup;

      if (subscriptionCookie === null || subscriptionCookie < 3) {
        if (subscriptionCookie === null) {
          subscriptionCookie = 0;
        }
        subscriptionCookie = parseInt(subscriptionCookie) + 1;
        $.cookie('popupCookie', subscriptionCookie, {
          expires: 1,
          path: '/'
        });
        if (subscriptionCookie === 3 && !disableNewsletterSignupPopup) {
          this.showOverlayForm();
        }
      }
    },

    setEvents: function (context) {
      $('.js-subscribe-popup', context).on('submit', this.submitPopupForm);
    },

    init: function (context, settings) {
      var self = this;

      self.checkCookie(settings);
      $('.js-subscribe-popup--v1', context).each(function (i, obj) {
        var $context = $(obj);

        self.setEvents($context);
      });
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.init(context, settings);
    }
  };
})(jQuery);
